
[class*="breadcrumbsBanner"] {
  .carousel-inner {
    height: 400px;

    .carousel-item {
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    .carousel-inner {
      height: auto;

      .carousel-item {
        img {
          margin-top: 5rem;
          width: 100%;
        }
      }
    }}
  @media (max-width: 600px) {
    .carousel-inner {
      height: auto;

      .carousel-item {
        img {
          margin-top: 5rem;
          width: 100%;
        }
      }
    }
  }

}