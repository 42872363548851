
[class*="enrichList"] {
  
  .form-check-input[type=checkbox], .form-check-input[type=radio]{
    border: 1px solid #878787;
    padding: 12px;
    margin-right: 10px;
    margin-top: 0;
  }
  .form-check-input:checked {
    background-color: #fbb33a;
  }
 .dropdown-toggle::after{
  margin-left: 0.5em;
    vertical-align: 0.1em;
 }
  .pagination{
    .page-item{
      margin-right: 0.4rem;
      .page-link{
        border-radius: 9999px;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 2.75rem;
        display: inline-flex;
        color: #212529;
        font-weight: 500;
        &.active{
          background-color: #0284c7;
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .form-check-input[type=checkbox], .form-check-input[type=radio]{
      border: 1px solid #878787;
      padding: 9px;
      margin-right: 10px;
      margin-top: 0;
    }
  }
}