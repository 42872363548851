.loadingSec {
    .loadingWrapper {
        position: relative;
        top: -50;
        right: 0;
        text-align: center;

        .loadingImage {
            width: calc(5px + 100px);
            left: 50%;
            margin-left: -4em;
            margin-top: 100px;
            // border: '7px solid rgb(180, 180, 180)',
            // borderLeft: '7px solid #214ba3',
            animation: spin 1.1s infinite linear;
        }
    }
}


