.App {
  // text-align: center;
}

a {
  text-decoration: none;
  color: #212529;
}

p {
  font-size: 16px;
}


[class*="associationEvent"] {

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none !important;
  }

}
[class*="registerwrapper"] {

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none !important;
  }

}

.yogaBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)), url(../src/assets/images/yoga.jpg);
}

.computerClassBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.5)), url(../src/assets/images/computer-class.jpg);
}



