[class*="communityEnrichmentWrapper"] {
    .carousel {
      .carousel-inner {
      }
      .carousel-indicators {
        display: none;
      }
      .carousel-control-prev {
        right: 66px;
        height: fit-content;
        top: -93px;
        left: auto;
        display: block;
        width: auto;
        display: flex;
        align-items: center;
        border: 2px solid #e2e8f0;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        span {
            width: 22px;
        }
      }
      .carousel-control-next {
        right: 8;
        height: fit-content;
        top: -93px;
        display: block;
        width: auto;
        display: flex;
        align-items: center;
        border: 2px solid #e2e8f0;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        span {
            width: 22px;
        }
      }
    }
  
  
      .form-check-input[type=checkbox], .form-check-input[type=radio]{
        border: 1px solid #878787;
        padding: 9px;
        margin-right: 10px;
        margin-top: 0;
        font-size: 11px;
      }
      .form-check-input:checked {
        background-color: #fbb33a;
      }
     .dropdown-toggle::after{
      margin-left: 0.5em;
        vertical-align: 0.1em;
     }
      .pagination{
        .page-item{
          margin-right: 0.4rem;
          .page-link{
            border-radius: 9999px;
            justify-content: center;
            align-items: center;
            width: 2.75rem;
            height: 2.75rem;
            display: inline-flex;
            color: #212529;
            font-weight: 500;
            &.active{
              background-color: #0284c7;
              color: #fff;
            }
          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      [class*="communityEnrichmentWrapper"] {
        .carousel {
          .carousel-control-next, .carousel-control-prev {
            top: -55px;
            display: none;
          }
        }
        
      @media (max-width: 992px) {
        .form-check-input[type=checkbox], .form-check-input[type=radio]{
          border: 1px solid #878787;
          padding: 9px;
          margin-right: 10px;
          margin-top: 0;
        }
      }
    }
    }