[class*="enrichmentWrapper"] {
  .carousel {
    .carousel-indicators {
      display: none;
    }

    .carousel-control-prev {
      right: 66px;
      height: fit-content;
      top: -93px;
      left: auto;
      display: block;
      width: auto;
      display: flex;
      align-items: center;
      border: 2px solid #e2e8f0;
      border-radius: 100%;
      width: 45px;
      height: 45px;

      span {
        width: 22px;
      }
    }

    .carousel-control-next {
      right: 8;
      height: fit-content;
      top: -93px;
      display: block;
      width: auto;
      display: flex;
      align-items: center;
      border: 2px solid #e2e8f0;
      border-radius: 100%;
      width: 45px;
      height: 45px;

      span {
        width: 22px;
      }
    }
  }
  .form-check-input[type=checkbox], .form-check-input[type=radio]{
    border: 1px solid #878787;
    padding: 9px;
    margin-right: 10px;
    margin-top: 0;
    font-size: 11px;
  }
  .form-check-input:checked {
    background-color: #fbb33a;
  }
}

@media screen and (max-width: 600px) {
  [class*="enrichmentWrapper"] {
    .carousel {
      .carousel-control-next, .carousel-control-prev {
        top: -55px;
        display: none;
      }
    }
  }
}