[class*="loginWrapper"] {
  .carousel{
    .carousel-indicators{
      display: none;
    }
    .carousel-control-prev-icon{
      display: none;
    }
    .carousel-control-next-icon{
      display: none;
    }
  }
}